import React from 'react'
import { useTranslation } from 'react-i18next'

import Select from '../../components/UI/Select'

import useLocalStorage from '../../hooks/useLocalStorage'

import languageBlack from '../../assets/lang.svg'

import { LanguageImage } from './style'
import { useDispatch } from 'react-redux'
import { setDir } from '../../slices/lang.slice'

export default function LanguageSwitch ({ setLangChange, onChange }) {
  const dispatch = useDispatch()
  const [lang, setLang] = useLocalStorage('lang', { lang: 'en', dir: 'ltr' })

  const category = [{
    value: 'en',
    label: <div><LanguageImage src={languageBlack} />EN</div>
  }, {
    value: 'fr',
    label: <div><LanguageImage src={languageBlack} />FR</div>
  },
  {
    value: 'ar',
    label: <div><LanguageImage src={languageBlack} />AR</div>
  }]
  const { i18n } = useTranslation()
  const changeLanguage = lng => {
    setLangChange && setLangChange(lng)
    setLang({ lang: lng, dir: i18n.dir(lng) })
    i18n.changeLanguage(lng)
    dispatch(setDir({ textDirection: i18n.dir(), lang: lng }))
  }

  return (
    <Select
      handleOnChange={(event) => {
        changeLanguage(event)
        onChange && onChange()
      }}
      name='category'
      options={category}
      countrySelector
      defaultValue={lang.lang === 'en' ? category[0] : category.filter(item => item.value === lang.lang)}
    />
  )
}
