import styled from 'styled-components'
import Box from '@material-ui/core/Box'

export const Container = styled.div``
export const Wrapper = styled.div`
  ${({ theme }) => `
display: flex;
position:relative;
background-color: ${theme.colors.primaryMain}};
}
h2 {
    font-size: ${theme.fontSizes.exLarge};
    font-weight: bold;
    color: ${theme.colors.white};
    padding: 0 15px 0 60px;
    flex: 0 0 65%;
    ${theme.textDirection === 'rtl' ? `
      padding-right: 60px;
      padding-left: 0;
    ` : ''}
    
}
.MuiSvgIcon-root {
  position: absolute;
  top: 23px;
  fill: white;
  left: 18px;
}
#menuIcon {
  height:16px;
  width:22px;
  position:absolute;
  left:0;
  padding-left: 20px;
  top: 38%;
  z-index: 99;
  ${theme.textDirection === 'rtl' ? `
    left: auto;
    right: 0;
    padding-right: 20px;
    transform: scale(-1, 1);
  ` : ''}
}
`}
.sidebar-country-select {
  padding: 20px 0px;
}
`

export const SideBarHeader = styled.div`
  height: 116px;
  border-bottom: 1px solid #e8e8e8;
  display: flex;
  justify-content: center;
  align-items: center;
`
export const HeaderImage = styled.img`
    max-width: 237px;
    width: 100%;
`
export const SideBarBody = styled.div`
  ${({theme}) => `
      display: flex;
      flex-direction: column;
      flex: 1;
      justify-content: flex-start;
      align-items: center;
    
      > div {
        margin: 21px 0px;
        width: 100px;
        ${theme.textDirection === 'rtl' ? `
          width: auto;
          padding: 0 10px;
        ` : ''}
      }
  `}
`
export const SideBarFooter = styled.div`
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
`
export const CountrySelect = styled.div`
  padding: 37px 0px 60px 0px;
  text-align: center;
  display: flex;
  justify-content: center;
  .langSw {
    width: 100px;
  }
`
export const RegisterButton = styled.div`
  text-align: center;
  a {
    font-size: 16px;
    text-decoration: none;
    color: #000;
    cursor: pointer;
  }
`

export const LoginButton = styled.div`
  background: #005f9a;

  border: none;
  width: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  cursor: pointer;

  height: 42px;
  border-radius: 19px;
  a {
    color: #fff;
    text-decoration: none;
  }
`
export const FooterWrapper = styled(Box)`
  height: 80px;
`
export const ContentSection = styled.div``

export const AboutIPUImg = styled.img`
  margin-top: 1rem;
  width: 100%;
`
export const SectionParaBox = styled.div`
  padding: 1rem;
  padding-bottom: 2rem;
`
export const SectionPara = styled.div`
${({ theme }) => `
  font-size: 14px;
  padding-top: ${theme.fontSizes.exLarge};
  line-height: 1.5;
  .site-link {
    color: #005f9a;
    text-decoration: none;
  }
  &.secondSection {
    padding: 1rem;
  }
`}`
export const SectionReadMore = styled.div`
  padding-top: 30px;
  font-size: 14px;
  padding-right: 0rem;
`
export const ReadMorePoint = styled.img`
 margin-right: 6px;`
export const ReadMoreText = styled.a`
  color: #00aabe;
  text-decoration: underline;
  font-size: 14px;
`
export const ContentBox = styled.div`
  padding: 0rem;
`
export const Section = styled.div`
  display: flex;
`
export const SectionTitle = styled.div`
  font-size: 20px;
  font-family: Roboto;
  font-weight: bold;
  padding: 0 1rem;
`

export const SectionLine = styled.div`
  ${(theme) => `
    background-color: #e98300;
    width: 200px;
    height: 5px;
    position: relative;
    margin: 32px 0px 0 0;
    margin-top: 10px;
    margin-bottom: 2rem;
    right: ${theme.textDirection === 'rtl' ? '-16%' : 'auto'};
    left: ${theme.textDirection === 'ltr' ? '-16%' : 'auto'};
  `}
`

export const SectionParaBoxTitle = styled.div`
  font-size: 16px;
  font-family: Roboto;
  padding-bottom: 0px;
`
