import React, { useEffect, useState } from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Snackbar, IconButton } from '@material-ui/core'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import CancelIcon from '@material-ui/icons/Cancel'
import CloseIcon from '@material-ui/icons/Close'

import ErrorBoundary from './components/ErrorBoundary'
import LandingPage from './pages/LandingPage/component'
import Signin from './pages/Signin'
import Events from './pages/Events'
import Forgot from './pages/Forgot'
import NotFound from './pages/NotFound'
import EventsDetails from './pages/EventsDetails'
import PrivateRoute from './PrivateRoute'
import PublicRoute from './PublicRoute'
import Registration from './pages/Registration'
import ResetPassword from './pages/RestPassword'
import Profile from './pages/Profile'
import Faq from './pages/Faq'
import NewsDetails from './pages/NewsDetails'
import News from './pages/News'
import Articles from './pages/Articles'
import ArticleDetails from './pages/ArticlesDetails'
import Alerts from './pages/Alerts'
import MessageDetails from './pages/MessageDetail'
import Hlag from './pages/Hlag'
import Static from './pages/Static'
import HLAGIndividual from './pages/HLAGIndividualUser'
import { firebaseMessagingInstance } from './firebase'
import { setRefreshAlertsState } from './slices/alerts.slice'
import { clearSnackbar } from './slices/snackbar.slice'

const Routes = ({ firebaseInstance }) => {
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const handleClose = () => {
    setSnackbarOpen(false)
  }

  const data = useSelector((state) => state.snackbar)

  useEffect(() => {
    if (firebaseMessagingInstance) {
      firebaseInstance.onMessage((payload) => {
        navigator.serviceWorker.getRegistration('/firebase-cloud-messaging-push-scope').then(registration => {
          const { title, body } = payload.data
          const notificationOptions = {
            body: body,
            icon: '/logo192.jpg'
          }
          registration.showNotification(title, notificationOptions)
        })
        dispatch(setRefreshAlertsState(true))
      })
    }
  }, [dispatch, firebaseInstance])

  useEffect(() => {
    data.message && data.status !== 404 ? setSnackbarOpen(true) : setSnackbarOpen(false)
    setTimeout(function () {
      dispatch(clearSnackbar())
    }, 5000)
  }, [data])

  const regMsg = ["Inscription réussi. Veuillez attendre l'approbation de l'administrateur",
    'Registration successful. Please wait for admin approval']
  const alertsMsg = ['Une nouvelle alerte a été envoyée!', 'A new alert has been sent!']
  return (
    <ErrorBoundary>
      <Router basename={process.env.REACT_APP_BASE_PATH}>
        <Switch>
          <PublicRoute component={LandingPage} path='/' exact />
          <PublicRoute component={Signin} path='/login' exact />
          <PublicRoute component={Forgot} path='/password/forgot' exact />
          <PrivateRoute component={Events} path='/events' exact />
          <PrivateRoute component={Articles} path='/articles' exact />
          <PrivateRoute component={EventsDetails} path='/eventsdetails/:id' exact />
          <PrivateRoute component={NewsDetails} path='/newsdetails/:id' exact />
          <PrivateRoute component={ArticleDetails} path='/articlesdetails/:id' exact />
          <PrivateRoute component={MessageDetails} path='/messagedetails/:id' exact />
          <PublicRoute component={Registration} path='/signup' exact />
          <PublicRoute component={ResetPassword} path='/password/reset' exact />
          <PrivateRoute component={Profile} path='/profile' exact />
          <PrivateRoute component={Faq} path='/faq' exact />
          <PrivateRoute component={News} path='/news' exact />
          <PrivateRoute component={Alerts} path='/alerts' exact />
          <PrivateRoute component={Hlag} path='/hlag' exact />
          <PrivateRoute component={Static} path='/static' exact />
          <PrivateRoute component={HLAGIndividual} path='/individualUser' exact />
          <Route path='*'>
            <NotFound />
          </Route>
        </Switch>
      </Router>
      <Snackbar
        anchorOrigin={{
          vertical: alertsMsg.includes(data.message) ? 'bottom' : 'top',
          horizontal: 'center'
        }}
        open={snackbarOpen}
        autoHideDuration={regMsg.includes(t(data.message)) ? null : 4000}
        onClose={handleClose}
        message={
          <>
            {data.type &&
              <IconButton
                className={`snackbar-${data.type}`}
                size='small'
                aria-label='close'
                color='inherit'
                onClick={handleClose}
              >
                {
                  data.type === 'SUCCESS'
                    ? (
                      <CheckCircleOutlineIcon fontSize='small' />
                      )
                    : (
                      <CancelIcon fontSize='small' />
                      )
                }
              </IconButton>}
            {t(data.message)}
          </>
        }
        action={
          <>
            <IconButton
              className='snackbar-action'
              size='small'
              aria-label='close'
              color='inherit'
              onClick={handleClose}
            >
              <CloseIcon fontSize='small' />
            </IconButton>
          </>
        }
      />
    </ErrorBoundary>
  )
}

export default Routes
