import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  textDirection: '',
  lang: ''
}

const lang = createSlice({
  name: 'lang',
  initialState,
  reducers: {
    setDir: (state, action) => {
      state.textDirection = action.payload.textDirection
      state.lang = action.payload.lang
    }
  }
})

export const { setDir } = lang.actions
export default lang.reducer
