const lang = JSON.parse(window.localStorage.getItem('lang'))

const theme = {
  colors: {
    primaryMain: '#005f9a',
    primaryContrast: '#fff',
    primaryBorder: '#2c3249',
    primaryBg: '#181c2d',
    grey: '#999',
    normalLink: '#f8f9f9',
    modalText: '#747678',
    modalHeading: '#575757',
    listText: '#889ccc',
    lightBorder: '#2d2b2b',
    secondaryMain: '#20b1e8',
    white: '#fff',
    inputBackground: '#efefef',
    red: '#ff0000',
    black: '#000',
    background: '#1b1b1b',
    warning: '#ffff00',
    paginationBackground: '#181c2d',
    paginationColor: '#fff',
    blue: '#005f9a',
    lightBlue: '#00aabe',
    contentTag: '#e98300',
    loader: 'rgba(0, 0, 0, 0.3)',
    voice: '#f86671',
    detailsBg: '#f8f8f8',
    dialogBg: 'rgba(0, 0, 0, 0.6)',
    detailsContent: '#f9fafaba',
    regHead: '#fefeff'
  },
  fontSizes: {
    exSmall: '10px',
    small: '12px',
    medium: '14px',
    large: '16px',
    exLarge: '20px',
    footer: {
      label: '11px',
      badge: '8px'
    },
    name: {
      small: '18px',
      medium: '22px',
      large: '24px'
    }
  },
  boxShadows: {
    upcoming: '0 9px 20px 0 rgba(0, 95, 154, 0.3)',
    nonUpcoming: '0 5px 10px 0 rgba(116, 118, 120, 0.1)',
    mediaCard: '0 4px 9px 0 rgba(116, 118, 120, 0.1)',
    dialog: '0 3px 14px 0 rgba(0, 0, 0, 0.11)',
    footer: '0 -16px 24px 0 rgba(150, 150, 150, 0.15)',
    profilepic: '0 7px 8px 0 rgba(0,0,0,0.2)'
  },
  border: {
    card: 'solid 2px rgba(116, 118, 120, 0.1)',
    calender: 'solid 1px #eaeaea',
    mediaCard: 'solid 1.8px rgba(116, 118, 120, 0.1)',
    accordian: '1px solid rgba(116, 118, 120, 0.16)',
    cancelDialog: 'solid 2px #747678',
    deleteDialog: 'solid 2px #e98300',
    inputFocused: '1px solid #00aabe',
    inputFilled: '1px solid rgba(116, 118, 120, 0.5)',
    selectControl: 'solid 1px #00aabe',
    countrySelect: 'solid 1.5px rgba(151, 151, 151, 0.22)',
    detailsContent: '1px solid #f4f4f4',
    buttonNotFound: '2px solid #005f9a'
  },
  background: {
    main: 'linear-gradient(162deg, #005f9a 14%, #00aabe 86%)',
    forgot: 'linear-gradient(162deg, #005f9a 6%, #00aabe 50%)',
    reg: 'linear-gradient(162deg, #005f9a 6%, #00aabe 86%)',
    rtl: 'linear-gradient(196deg, #005f9a 14%, #00aabe 86%)',
  },
  textDirection: lang?.dir || 'ltr'
}

export default theme
