import React, { useEffect } from 'react'
import { ThemeProvider } from 'styled-components'
import { I18nextProvider } from 'react-i18next'
import PWAPrompt from 'react-ios-pwa-prompt'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import { Snackbar, IconButton } from '@material-ui/core'
import TagManager from 'react-gtm-module'
import store from './store'

import theme from './config/theme'
import GlobalTheme from './config/theme/globalTheme'
import i18n from './config/locales/i18n'
import useLocalStorage from './hooks/useLocalStorage'
import Routes from './Router'
import Styled from './privateStyle'
import WifiOffIcon from '@material-ui/icons/WifiOff'
import pattern from '../src/assets/pattern.png'
import Button from '../src/components/UI/Button'
import { createFirebaseInstance } from './firebase'
import { sendFirebaseToken } from './slices/alerts.slice'
import { getLocalStore, checkForIOS, refreshLocalStorage } from './utils/utils'
import { Helmet } from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'
import { setDir } from './slices/lang.slice'

function App () {
  const auth = getLocalStore('AUTH_DETAILS_MOBILE')
  const tagManagerArgs = {
    gtmId: process.env.REACT_APP_GTM_ID
  }
  const [open, setOpen] = React.useState(false)
  const [openUpdate, setOpenUpdate] = React.useState(false)
  const [firebaseInstance] = React.useState(createFirebaseInstance())
  const [lang, setLang] = useLocalStorage('lang', { lang: 'en', dir: 'ltr' })
  const [defaultTheme, setTheme] = React.useState(theme)

  const storeData = useSelector(state => state.lang)

  window.addEventListener('online', () => {
    setOpen(false)
  })
  window.addEventListener('offline', () => {
    setOpen(true)
  })

  const dispatch = useDispatch()

  useEffect(() => {
    refreshLocalStorage()
    setLang(lang || { lang: 'en', dir: 'ltr' })
    i18n.changeLanguage(lang.lang)
    dispatch(setDir({ textDirection: lang.dir, lang: lang.lang }))
    updateTheme()
  }, [])

  const updateTheme = () => {
    store.subscribe(() => {
      const newTheme = defaultTheme
      newTheme.textDirection = i18n.dir()
      setTheme(newTheme)
    })
  }

  const reloadPage = () => {
    if (window.navigator.onLine) {
      window.location.reload()
    }
  }
  useEffect(() => {
    serviceWorkerRegistration.register({
      onUpdate: (registration) => {
        const waitingServiceWorker = registration.waiting
        if (waitingServiceWorker) {
          waitingServiceWorker.postMessage({ type: 'SKIP_WAITING' })
        }
        setOpenUpdate(true)
      }
    })
  }, [])

  const handleClose = () => {
    setOpenUpdate(false)
    window.location.reload()
  }

  const [isIOS, setIsIOS] = React.useState(false)

  useEffect(() => {
    setIsIOS(checkForIOS())
    TagManager.initialize(tagManagerArgs)
  }, [])

  useEffect(() => {
    if (firebaseInstance) {
      firebaseInstance.requestPermission().then(() => {
        return firebaseInstance.getToken()
      }).then((data) => {
        if ('localStorage' in window && window.localStorage !== null) {
          window.localStorage.setItem('firebaseToken', data)
        }
        auth && sendFirebaseToken({ token: data })
      }).catch(() => {})
    }
  }, [isIOS, auth])

  return (
    <I18nextProvider i18n={i18n}>
      {isIOS && <PWAPrompt />}
      <Helmet htmlAttributes={{ lang: storeData.lang, dir: storeData.textDirection }} />
      <GlobalTheme />
      <ThemeProvider theme={defaultTheme}>
        {open
          ? (
            <Styled.NoNetWrapper>
              <img src={pattern} id='bgContainer' />
              <Styled.NoNetWrapperData>
                <WifiOffIcon />
                <div className='head'>
                  {i18n.t('app.noNet')}
                </div>
                <div className='content'>
                  {i18n.t('app.noNetMsg')}
                </div>
                <Styled.ButtonWrapper>
                  <Button
                    onClick={() => reloadPage()}
                    label={i18n.t('app.retry')}
                  />
                </Styled.ButtonWrapper>
              </Styled.NoNetWrapperData>
            </Styled.NoNetWrapper>)
          : <Routes firebaseInstance={firebaseInstance} />}
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
          open={openUpdate}
          autoHideDuration={7000}
          message={i18n.t('app.update')}
          className='snackbar-update'
          action={
            <>
              <IconButton
                className='snackbar-action-update'
                size='small'
                aria-label='close'
                color='inherit'
                onClick={() => handleClose()}
              >
                {i18n.t('app.reload')}
              </IconButton>
            </>
            }
        />
      </ThemeProvider>
    </I18nextProvider>
  )
}

export default App
