export default {
  Edit: 'Éditer',
  SavetoReload: 'et enregistrer pour recharger',
  Name: 'Nom',
  events: {
    heading: 'Evénements/ Réunions',
    UpcomingEvents: 'Évènements à venir',
    register: 'Inscrivez-vous ici',
    documents: 'Documents',
    speeches: 'Discours',
    files: 'Fichiers',
    linktoregister: "Lien vers l'inscription"
  },
  footer: {
    events: 'Evénements/ Réunions',
    news: 'Actualités',
    articles: 'Articles',
    faq: 'FAQ',
    alerts: 'Alertes'
  },
  login: {
    heading: "S'identifier",
    email: 'Adresse e-mail',
    password: 'Mot de passe',
    forgot: 'Mot de passe oublié?',
    new: 'Nouvel utilisateur?',
    button: "S'IDENTIFIER",
    data: 'Veuillez saisir vos coordonnées pour vous connecter'
  },
  hamburger: {
    hlag: 'Membres du Groupe consultatif de haut niveau',
    globparNet: 'Réseau parlementaire mondial',
    intLeg: 'Législations internationales (SHERLOC)',
    unodc: 'ONUDC et ONUCT',
    ipu: 'Union interparlementaire',
    about: "Programme de Lutte contre le terrorisme et l'extrémisme violent",
    logout: 'Se déconnecter'
  },
  register: {
    heading: "S'inscrire",
    haveAccount: 'Vous avez déjà un compte?',
    login: "S'IDENTIFIER",
    data: 'Veuillez saisir vos coordonnées pour vous inscrire'
  },
  forgot: {
    heading: 'Mot de passe oublié',
    content: 'Entrez votre adresse e-mail ci-dessous et nous vous enverrons des instructions afin de changer votre mot de passe.',
    link: 'ACCÉDER À LA CONNEXION'
  },
  submit: 'SOUMETTRE',
  common: {
    save: 'Sauvegarder',
    fieldRequiredMessage: 'Ce champ est obligatoire',
    invalidEmail: 'Adresse e-mail invalide',
    loading: 'Loading',
    emailValidationMessage: 'Veuillez entrer une adresse e-mail valide',
    passwordMatch: 'les mots de passe doivent correspondre',
    submit: 'SOUMETTRE',
    passwordValidation: 'Le mot de passe doit avoir au moins un caractère majuscule, un caractère minuscule, un chiffre, un caractère spécial et doit contenir au moins 8 caractères',
    subscribe: 'Souscrire',
    cancel: 'Annuler',
    update: 'Mise à jour',
    emailError: 'L\'e-mail principal et l\'e-mail secondaire doivent être différents\'',
    nameValidation: 'Veuillez entrer un nom valide',
    passwordLength: 'Le mot de passe doit contenir au moins 8 caractères'
  },
  1000: 'Votre session est expirée. Veuillez vous connecter pour continuer.',
  1001: "L'adresse e-mail principale n'est pas valide.",
  1002: "L'adresse 'e-mail secondaire n'est pas valide.",
  1003: 'Les mots de passe ne correspondent pas.',
  1004: 'La longueur minimale du mot de passe doit être de 8 caractères.',
  1005: 'Le mot de passe doit contenir au moins un caractère spécial.',
  1006: 'Le mot de passe doit contenir au moins une lettre majuscule.',
  1007: 'Le mot de passe doit contenir au moins une lettre minuscule.',
  1008: 'Le mot de passe doit contenir au moins un chiffre.',
  1009: "La catégorie de membres de l'UIP n'est pas valide.",
  1010: "L'image avatar de l'avatar n'a pas pu être traitée.",
  1011: 'Impossible de lire le fichier image.',
  1012: 'Email déjà enregistré.',
  1013: "L'e-mail principal ne peut pas être l'e-mail secondaire de l'utilisateur existant.",
  1014: "L'e-mail secondaire a déjà été utilisé.",
  1015: 'Les données de l’utilisateur ont été  mises à jour avec succès.',
  1016: "L'utilisateur n'existe pas.",
  1017: 'Nous avons envoyé votre lien de réinitialisation de mot de passe!',
  1018: "Impossible d'envoyer l'e-mail de réinitialisation du mot de passe.",
  1019: "L'e-mail personnel a déjà été enregistré.",
  1020: 'Le mot de passe a été mis à jour avec succès. Vous serez bientôt déconnecté.',
  1021: "L'ancien mot de passe est incorrect.",
  1022: 'Utilisateur invalide.',
  1023: 'Le lien de réinitialisation du mot de passe a expiré!',
  1024: 'Le mot de passe a été mis à jour avec succès.',
  1025: 'Lien de réinitialisation du mot de passe non valide.',
  1026: 'Succès',
  1027: 'Le compte d’utilisateur a été supprimé avec succès.',
  1028: "Votre compte n'est pas actif.",
  1033: "Le compte d’utilisateur n'a pas été activé ou est bloqué.",
  1034: 'E-mail ou mot de passe incorrect.',
  1035: 'Trop de tentatives de connexion échouées à partir de votre adresse IP. Cette adresse IP est temporairement bloquée.',
  1036: "Cet itinéraire n'est accessible qu'aux utilisateurs anonymes.",
  1037: "Inscription réussi. Veuillez attendre l'approbation de l'administrateur.",
  1038: 'Mot de passe mis à jour.Vous serez bientôt déconnecté.',
  1039: 'Le site web de l’UIP est actuellement en cours de maintenance. Nous devrions être de retour bientôt. Merci de votre patience.',
  upload: {
    upload: 'Télécharger la photo',
    change: 'Modifier la photo de profil',
    remove: 'Retirer',
    sizeError: 'Les images téléchargées doivent être inférieurs à 2 Mo'
  },
  Upload: 'Télécharger une photo de profil',
  Select: 'Sélectionner',
  Email: 'Adresse e-mail',
  SecondaryEmail: 'Adresse e-mail secondaire (facultatif)',
  Copyright: '© Copyright IPU',
  IPUMembershipCategory: "Catégorie de membres de l'UIP",
  ConfirmPassword: 'Confirmez le mot de passe',
  Login: "S'IDENTIFIER",
  Register: "S'INSCRIRE",
  Profile: 'Profil',
  Logout: 'Se déconnecter',
  ProfilePhoto: 'Photo de profil',
  Secondarymail: 'Adresse e-mail secondaire',
  Password: 'Mot de passe',
  ChangePassword: 'Changer le mot de passe',
  reset: {
    heading: 'Réinitialiser le mot de passe'
  },
  profile: {
    heading: 'Profil',
    fullName: 'Nom complet',
    edit: 'Modifier le profil',
    delete: 'Supprimer le compte',
    currentPass: 'Mot de passe actuel',
    newPass: 'Nouveau mot de passe',
    confPass: 'Confirmer le nouveau mot de passe',
    Name: 'Nom'
  },
  delete: {
    title: 'Supprimer le compte?',
    content: 'Voulez-vous vraiment supprimer ce compte?',
    delete: 'Supprimer',
    cancel: 'Annuler',
    picContent: 'Voulez-vous vraiment retirer cette image?',
    picTitle: "Retirer l'image?",
    remove: 'Retirer'
  },
  apologies: "Désolé. Nous n'avons pas trouvé la page que vous recherchiez.",
  goHome: 'ALLER À LA MAISON',
  goLogin: 'ACCÉDER À LA PAGE D’ACCUEIL',
  Enterkeyword: 'Entrez mot-clé à rechercher...',
  SelectDate: 'Sélectionner la date',
  faq: {
    header: 'FAQ',
    noresult: 'Aucun résultat trouvé!'
  },
  news: {
    heading: 'Actualités',
    UpcomingEvents: 'Tendance Actuelle'
  },
  articles: {
    heading: 'Des articles',
    latest: 'Derniers articles'
  },
  alerts: {
    heading: 'Alertes',
    UpcomingEvents: 'Les alertes de plus de 2 semaines seront supprimées automatiquement'
  },
  eventTypes: {
    message: 'Message',
    event: 'Événement',
    news: 'Actualités',
    article: 'Articles',
    event_meeting: 'Événements/ Réunions'
  },
  message: {
    noData: 'Pas de données disponibles'
  },
  app: {
    reload: 'RECHARGER',
    noNetMsg: 'Nous ne pouvons détecter aucune connectivité Internet. Veuillez vérifier votre connexion internet et réessayer.',
    noNet: 'Pas de connexion Internet!',
    update: 'Une nouvelle mise à jour est disponible!',
    retry: 'RÉESSAYER',
    wrong: "Une erreur s'est produite!",
    someWrong: "Une erreur s'est produite. Vous pouvez réessayer maintenant ou réessayer plus tard."
  },
  hlag: {
    composition: 'Composition du Groupe consultative de haut niveau :',
    members: 'Membres',
    terms: 'Termes de référence :',
    meeting: 'Réunions / rapports :',
    other: 'Autres informations :',
    group: 'Position dans le groupe',
    parliament: 'Position au parlement',
    geoGroup: 'Groupe géopolitique',
    sizeError: 'Les fichiers téléchargés doivent être inférieures à 2 Mo',
    Resume: 'CV',
    Upload: 'Télécharger le CV',
    accept: 'Accepts only pdf, doc or docx'
  },
  individualHLAG: {
    PositionInGroup: 'Position dans le groupe',
    PositionInParliament: 'Position au parlement',
    GeopoliticalGroup: 'Groupe géopolitique',
    EmailID: 'E-mail ID'
  },
  monthsShort: ['Janv', 'Févr', 'Mars', 'Avr', 'Mai', 'Juin', 'Juill', 'Août', 'Sept', 'Oct', 'Nov', 'Déc']
}
