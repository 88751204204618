export default {
  Edit: 'تعديل',
  SavetoReload: 'حفظ لإعادة التحميل',
  Name: 'الاسم',
  events: {
    heading: 'مناسبات/ اجتماعات',
    UpcomingEvents: 'المناسبات القادمة',
    register: 'إنشاء حساب',
    documents: 'المستندات',
    speeches: 'الخطابات',
    files: 'الملفات',
    linktoregister: 'رابط إنشاء حساب'
  },
  footer: {
    events: 'مناسبات/ اجتماعات',
    news: 'أخبار',
    articles: 'مقالات',
    faq: 'أسئلة شائعة',
    alerts: 'التنبيهات'
  },
  login: {
    heading: 'تسجيل الدخول',
    email: 'عنوان البريد الإلكتروني',
    password: 'كلمة المرور',
    forgot: 'نسيت كلمة المرور؟',
    new: 'مستخدم جديد؟',
    data: 'الرجاء إدخال التفاصيل الخاصة بك لتسجيل الدخول',
    button: 'تسجيل الدخول'
  },
  hamburger: {
    hlag: 'أعضاء الفريق الاستشاري الرفيع المستوى',
    globparNet: 'الشبكة البرلمانية العالمية',
    intLeg: 'التشريعات الدولية ("بوابة شيرلوك")',
    unodc: 'مكتب الأمم المتحدة المعني بالمخدرات والجريمة و مكتب مكافحة الإرهاب',
    ipu: 'الاتحاد البرلماني الدولي',
    about: 'برنامج مكافحة الإرهاب والتّطرّف العنيف',
    logout: 'تسجيل الخروج'
  },
  register: {
    heading: 'إنشاء حساب',
    haveAccount: 'هل لديك حساب بالفعل؟',
    login: 'تسجيل الدخول',
    data: 'الرجاء إدخال التفاصيل الخاصة بك لتسجيل الدخول'
  },
  forgot: {
    heading: 'نسيت كلمة المرور',
    content: 'أدخل بريدك الإلكتروني فيما يلي، وسنرسل لك إرشادات حول كيفية إعادة تعيين كلمة المرور الخاصة بك',
    link: 'انتقل إلى تسجيل الدخول'
  },
  submit: 'إرسال',
  common: {
    save: 'حفظ',
    fieldRequiredMessage: 'هذا الحقل مطلوب',
    invalidEmail: 'العنوان خطأ',
    loading: 'جاري التحميل',
    emailValidationMessage: 'الرجاء إدخال عنوان بريد إلكتروني صالح',
    passwordMatch: 'يجب أن تكون كلمتا المرور متطابقتين',
    submit: 'إرسال',
    passwordValidation: 'يجب أن تتكون كلمة المرور من 8 أحرف على الأقل، ويجب أن تحتوي على أحرف كبيرة وصغيرة مثال (aA)، على رقم على الأقل مثال (7) ، وعلى أي من الرموز الخاصة الموجودة في لوحة المفاتيح مثال ($#&)',
    subscribe: 'اشتراك',
    cancel: 'إلغاء',
    update: 'تحديث',
    emailError: 'يجب أن يكون البريد الإلكتروني الأساسي والبريد الإلكتروني الثانوي مختلفين',
    nameValidation: 'الرجاء إدخال اسم صالح',
    passwordLength: 'يجب أن تتكون كلمة المرور من 8 أحرف على الأقل'
  },
  1000: 'انتهت جلستك. الرجاء تسجيل الدخول للمتابعة.',
  1001: 'البريد الإلكتروني الأساسي غير صالح.',
  1002: 'البريد الإلكتروني الثانوي غير صالح.',
  1003: 'كلمتا المرور غير متطابقتين.',
  1004: 'يجب أن تتكون كلمة المرور من 8أحرف على الأقل.',
  1005: 'يجب أن تحتوي كلمة المرور على رمز خاص على الأقل.',
  1006: 'يجب أن تحتوي كلمة المرور على حرف كبير على الأقل.',
  1007: 'يجب أن تحتوي كلمة المرور على حرف صغير على الأقل.',
  1008: 'يجب أن تحتوي كلمة المرور على رقم على الأقل.',
  1009: 'فئة العضوية بالاتحاد البرلماني الدولي غير صالحة.',
  1010: 'لا يمكن معالجة الصورة الرمزية.',
  1011: 'لا يمكن قراءة ملف الصورة.',
  1012: 'البريد الإلكتروني مسجل بالفعل.',
  1013: 'لا يمكن أن يكون البريد الإلكتروني الأساسي هو البريد الإلكتروني الثانوي للمستخدم الحالي.',
  1014: 'البريد الإلكتروني الثانوي مستخدم بالفعل.',
  1015: 'تم تحديث بيانات المستخدم بنجاح.',
  1016: 'المستخدم غير موجود.',
  1017: 'لقد أرسلنا لك عبر البريد الإلكتروني رابط إعادة تعيين كلمة المرور!',
  1018: 'تعذر إرسال البريد الإلكتروني الخاص بإعادة تعيين كلمة المرور.',
  1019: 'البريد الإلكتروني الشخصي مسجل بالفعل.',
  1020: 'تم تحديث كلمة المرور بنجاح. سيتم تسجيل خروجك بعد قليل.',
  1021: 'كلمة المرور القديمة غير صحيحة.',
  1022: 'مستخدم غير صالح.',
  1023: 'انتهت صلاحية رابط إعادة تعيين كلمة المرور!',
  1024: 'تم تحديث كلمة المرور بنجاح.',
  1025: 'رابط إعادة تعيين كلمة المرور غير صالح.',
  1026: 'تمت العملية بنجاح',
  1027: 'تم حذف حساب المستخدم بنجاح.',
  1028: 'حسابك غير نشط.',
  1033: 'لم يتم تنشيط حساب المستخدم أو قد تم حظره.',
  1034: 'البريد الإلكتروني أو كلمة المرور غير صحيحة.',
  1035: ' تم تسجيل الكثير من محاولات الدخول الفاشلة من عنوان بروتوكول الإنترنت الخاص بك. تم حظر عنوان بروتوكول الإنترنت هذا مؤقتا.',
  1036: 'لا يمكن الوصول إلى هذا المسار إلا من قبل مستخدمين مجهولين.',
  1037: 'تم التسجيل بنجاح. الرجاء انتظار موافقة المسؤول.',
  1038: 'تم تحديث كلمة المرور. سيتم تسجيل خروجك بعد قليل.',
  1039: 'موقع الاتحاد البرلماني الدولي قيد الصيانة حاليا.  سوف نعود قريبا. شكرا لك على صبرك.',
  Upload: 'تحميل صورة الصفحة الشخصية.',
  Select: 'اختيار',
  Email: 'عنوان البريد الإلكتروني',
  SecondaryEmail: 'عنوان البريد الإلكتروني الثانوي (اختياري)',
  Copyright: 'حقوق الملكية © الاتحاد البرلماني الدولي',
  IPUMembershipCategory: 'فئة العضوية بالاتحاد البرلماني الدولي',
  ConfirmPassword: 'تأكيد كلمة المرور',
  Login: 'تسجيل الدخول',
  Register: 'إنشاء حساب',
  Profile: 'الصفحة الشخصية',
  Logout: 'تسجيل الخروج',
  ProfilePhoto: 'صورة الصفحة الشخصية',
  Secondarymail: 'عنوان البريد الإلكتروني الثانوي',
  Password: 'كلمة المرور',
  ChangePassword: 'تغيير كلمة المرور',
  upload: {
    upload: 'تحميل صورة',
    change: 'تغيير صورة الصفحة الشخصية',
    remove: 'حذف',
    sizeError: 'يجب أن يكون حجم الصور التي تم تحميلها أقل من 2 ميجابايت'
  },
  reset: {
    heading: 'إعادة تعيين كلمة المرور'
  },
  profile: {
    heading: 'الصفحة الشخصية',
    fullName: 'الاسم الكامل',
    edit: 'تعديل الصفحة الشخصية',
    delete: 'حذف الحساب',
    currentPass: 'كلمة المرور الحالية',
    newPass: 'كلمة المرور الجديدة',
    confPass: 'تأكيد كلمة المرور الجديدة',
    Name: 'الاسم'
  },
  delete: {
    title: 'حذف الحساب؟',
    content: 'هل أنت متأكد من رغبتك في حذف هذا الحساب؟',
    delete: 'حذف',
    cancel: 'إلغاء',
    picContent: 'هل أنت متأكد من رغبتك في حذف هذه الصورة؟',
    picTitle: 'حذف الصورة؟',
    remove: 'حذف'
  },
  apologies: 'عفوا. قد تعذر علينا العثور على الصفحة التي تبحث عنها.',
  goHome: 'توجه إلى الصفحة الرئيسية',
  goLogin: 'توجه إلى صفحة تسجيل الدخول',
  Enterkeyword: 'أدخل كلمة أساسية للبحث...',
  SelectDate: 'حدد التاريخ',
  faq: {
    header: 'أسئلة شائعة',
    noresult: 'لم يتم العثور على نتائج!'
  },
  news: {
    heading: 'أخبار',
    UpcomingEvents: 'الشائعة حاليا'
  },
  articles: {
    heading: 'مقالات',
    latest: 'المقالات الأخيرة'
  },
  alerts: {
    heading: 'التنبيهات',
    UpcomingEvents: 'يتم حذف التنبيهات التي يزيد عمرها عن أسبوعين تلقائيا'
  },
  eventTypes: {
    message: 'بلاغ',
    event: 'مناسبة',
    news: 'أخبار',
    article: 'مقالات',
    event_meeting: 'مناسبات/ اجتماعات'
  },
  message: {
    noData: 'لا توجد بيانات متاحة'
  },
  app: {
    reload: 'إعادة التحميل',
    noNetMsg: 'تعذر علينا كشف أي اتصال بشبكة الإنترنت. الرجاء تحقق من اتصال الشبكة لديك وحاول مرة أخرى.',
    noNet: 'لا يوجد اتصال بالإنترنت!',
    update: 'هناك تحديث جديد متوفر!',
    retry: 'المحاولة مرة أخرى',
    wrong: 'حدث خطأ ما!',
    someWrong: 'حدث خطأ ما! يمكنك إما إعادة المحاولة الآن أو المحاولة مرة أخرى لاحقا.'
  },
  hlag: {
    composition: 'تكوين الفريق الاستشاري الرفيع المستوى:',
    members: 'الأعضاء',
    terms: 'الاختصاصات:',
    meeting: 'اجتماعات/ تقارير:',
    other: 'معلومات أخرى:',
    group: 'المنصب في الفريق',
    parliament: 'المنصب في البرلمان',
    geoGroup: 'المجموعة الجيوسياسية',
    sizeError: 'يجب أن يكون حجم الملفات التي تم تحميلها أقل من 2 ميجاباي',
    Resume: 'السيرة الذاتية',
    Upload: 'تحميل السيرة الذاتية',
    accept: 'يقبل فقط pdf أو doc أو docx'
  },
  individualHLAG: {
    PositionInGroup: 'المنصب في الفريق',
    PositionInParliament: 'المنصب في البرلمان',
    GeopoliticalGroup: 'المجموعة الجيوسياسية',
    EmailID: 'معرف البريد الإلكتروني'
  },
  monthsShort: ['يناير', 'فبراير', 'مارس', 'أبريل', 'مايو', 'يونيو', 'يوليو', 'أغسطس', 'سبتمبر', 'أكتوبر', 'نوفمبر', 'ديسمبر']
}
