import styled from 'styled-components'

export const LanguageImage = styled.img`
  ${({ theme }) => `
    ${theme.textDirection === 'rtl' ? 'margin-left: 4px;' : 'margin-right: 8px;'}
    vertical-align: middle;
    position: unset !important;
    top: 0 !important;
  `}
`
