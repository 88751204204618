/* global localStorage */

import AES from 'crypto-js/aes'
import CryptoJS from 'crypto-js'
import { LANG_TIME_FORMAT, UI_24_HOUR_FORMAT, UI_DATE_FORMAT, UI_TIME_FORMAT } from './constants'
import store from '../../store'
import moment from 'moment'
import jstz from 'jstz'
import i18n from '../../config/locales/i18n'

export const setLocalStore = (cname, cvalue) => {
  if (cvalue) {
    const encryptedData = AES.encrypt(JSON.stringify(cvalue), '123')
    localStorage.setItem(cname, encryptedData)
  }
}

export const setResponse = (cname, cvalue) => {
  if (cvalue) {
    localStorage.setItem(cname, cvalue)
  }
}

export const getResponse = (cname) => {
  return JSON.parse(localStorage.getItem(cname))
}

export const getLocalStore = (cname) => {
  if (cname === 'i18nextLng' || cname === 'eventlang' || cname === 'alertlang' || cname === 'newslang' || cname === 'articlelang' || cname === 'faqlang') return localStorage.getItem(cname)
  let decryptedData = null
  const bytes =
    localStorage.getItem(cname) &&
    AES.decrypt(localStorage.getItem(cname), '123')
  decryptedData = bytes && JSON.parse(bytes.toString(CryptoJS.enc.Utf8))
  return decryptedData
}

export const clearLocalStore = () => {
  const lang = localStorage.getItem('lang')
  localStorage.clear()
  localStorage.setItem('lang', lang)
}

export const objectArrayFromField = (list, field, value) => {
  return list?.length
    ? list.map((item) => {
        return {
          label: item[field],
          value: item[value]
        }
      })
    : []
}

export const deleteItem = (cname) => {
  localStorage.removeItem(cname)
}

export const setSearch = (cname, cvalue) => {
  if (cvalue) {
    localStorage.setItem(cname, JSON.stringify(cvalue))
  }
}

export const getData = (key, storeData, targetStorage) => {
  const persistedData = getResponse(targetStorage)
  const formattedData = persistedData || {}
  if (storeData && storeData[key] && storeData[key].length) {
    return storeData[key]
  }
  if (formattedData && formattedData[key] && formattedData[key].length) {
    return formattedData[key]
  }
  return []
}

export const infiniteScrollCheck = () => {
  if (navigator.vendor.includes('Apple')) {
    if ((window.innerHeight + window.pageYOffset) >= document.body.offsetHeight - 2) {
      return true
    }
  } else {
    if ((window.innerHeight + Math.ceil(window.pageYOffset + 1)) >= document.body.offsetHeight) {
      return true
    }
  }
}

export const checkForIOS = () => {
  const ua = window.navigator.userAgent
  const webkit = !!ua.match(/WebKit/i)
  const isIPad = !!ua.match(/iPad/i)
  const isIPhone = !!ua.match(/iPhone/i)
  const isIPad13 = !!ua.match(/Mac/i) && window.navigator.maxTouchPoints > 1
  const isIOS = isIPad || isIPhone || isIPad13
  const isSafari = isIOS && webkit && !ua.match(/CriOS/i)
  return isSafari
}

export const getDateFormat = () => {
  moment.updateLocale(store.getState().lang.lang, {
    monthsShort: i18n.t('monthsShort', { returnObjects: true })
  })

  return UI_DATE_FORMAT
}

export const getTimeFormat = () => {
  let timeFormat = UI_TIME_FORMAT
  if (LANG_TIME_FORMAT.includes(store.getState().lang.lang)) {
    timeFormat = UI_24_HOUR_FORMAT
  }
  return timeFormat
}

export const getDate = (startDate, endDate) => {
  const date = {
    date: '',
    time: ''
  }
  const profileDetails = getLocalStore('PROFILE_DETAILS')
  const timezone = jstz.determine().name()

  // Configure Date format
  const timeFormat = getDateFormat()
  const userProfileTimeZone = profileDetails.timezone
  const startDateFormatted = moment.tz(startDate, userProfileTimeZone).tz(timezone).format(timeFormat)
  const endDateFormatted = moment.tz(endDate, userProfileTimeZone).tz(timezone).format(timeFormat)

  if (startDateFormatted === endDateFormatted) {
    date.date = startDateFormatted
  }

  if (startDateFormatted !== endDateFormatted) {
    date.date = `${startDateFormatted} - ${endDateFormatted}`
  }

  // Configure time format
  const startTime = moment.tz(startDate, userProfileTimeZone).tz(timezone).format(getTimeFormat())
  const endTime = moment.tz(endDate, userProfileTimeZone).tz(timezone).format(getTimeFormat())

  if (startDateFormatted === endDateFormatted) {
    date.time = `${startTime} - ${endTime}`
  }

  // Changing time format with RTL mode
  if (store.getState().lang.textDirection === 'rtl' && (startDateFormatted === endDateFormatted)) {
    date.time = `${endTime} - ${startTime}`
  }

  return date
}

export const timeFormat = (createDate, startDate, endDate, alerts = false) => {
  let time = ''
  const profileDetails = getLocalStore('PROFILE_DETAILS')
  const timezone = jstz.determine().name()

  // Configure Date format
  const timeFormat = getDateFormat()
  const userProfileTimeZone = profileDetails.timezone
  const startDateFormatted = moment.tz(startDate, userProfileTimeZone).tz(timezone).format(timeFormat)
  const endDateFormatted = moment.tz(endDate, userProfileTimeZone).tz(timezone).format(timeFormat)

  const startTimeFormatted = moment.tz(startDate, userProfileTimeZone).tz(timezone).format(getTimeFormat())
  const endTimeFormatted = moment.tz(endDate, profileDetails.timezone).tz(timezone).format(getTimeFormat())

  // Format time
  if (alerts) {
    time = moment.tz(createDate, profileDetails.timezone).tz(timezone).format(getTimeFormat())
  }

  // Format time with range
  if (!alerts && (startDateFormatted === endDateFormatted)) {
    time = `${startTimeFormatted} - ${endTimeFormatted}`
  }

  // Format time with RTL mode and range
  if (!alerts && (startDateFormatted === endDateFormatted) && store.getState().lang.textDirection === 'rtl') {
    time = `${endTimeFormatted} - ${startTimeFormatted}`
  }

  return time
}

export const refreshLocalStorage = () => {
  const refreshed = window.localStorage.getItem('refreshed')
  if (!JSON.parse(refreshed)) {
    window.localStorage.clear()
    window.localStorage.setItem('refreshed', 'true')
  }
}
