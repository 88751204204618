/* global localStorage */

import { getLocalStore, clearLocalStore, setLocalStore, checkForIOS } from '../utils/index'
import reduxStore from '../../store'
import { showSnackbar } from '../../slices/snackbar.slice'
import { setDetails } from '../../slices/session.slice'

// Add a request interceptor
export function onRequest (config) {
  const auth = getLocalStore('AUTH_DETAILS_MOBILE')
  if (auth) {
    config.headers = {
      ...config.headers,
      Authorization: `Bearer ${auth.access_token}`
    }
  }
  config.headers = {
    ...config.headers,
    'IPU-Platform': 'mobile'
  }

  const appLang = JSON.parse(localStorage.getItem('lang'))

  if (!appLang.lang) {
    appLang.lang = 'en'
  }

  if (appLang.lang !== 'en') {
    config.url = `${appLang.lang}/${config.url}`
  }
  return config
}

export function onRequestError (error) {
  return Promise.reject(error)
}

// Add a response interceptor
export function onResponse (response) {
  const { dispatch } = reduxStore
  const customResponse = response
  const { status } = response
  if (status === 500) {
    return Promise.reject(getUnknownError())
  } else if (status === 400) {
    const { errorMessage } =
      response.data.error[0] || (response.data ? response.data.message : '')
    return Promise.reject(getUnknownError(errorMessage))
  }
  customResponse.data = response.data
  let customMessage = {}
  if (response?.config?.url?.includes('signup')) {
    customMessage = {
      message: getLocalStore('i18nextLng') === 'fr' ? "Inscription réussi. Veuillez attendre l'approbation de l'administrateur" : 'Registration successful. Please wait for admin approval',
      display: true,
      type: 'SUCCESS'
    }
  } else {
    customMessage = {
      message:
        response.data.message instanceof Array &&
        response.data.message[0]?.response_code,
      display:
        response.data.message instanceof Array &&
        response.data.message[0]?.display,
      type: 'SUCCESS'
    }
    const profileDetails = getLocalStore('PROFILE_DETAILS')
    if (profileDetails && response?.data) {
      if (checkForIOS() && (response?.data?.count_unread_alerts > profileDetails.count_unread_alerts)) {
        customMessage = {
          message: getLocalStore('i18nextLng') === 'fr' ? 'Une nouvelle alerte a été envoyée!' : 'A new alert has been sent!',
          display: true
        }
      }
      if (response?.data?.count_unread_alerts === 0) {
        profileDetails.count_unread_alerts = 0
      } else {
        profileDetails.count_unread_alerts = response?.data?.count_unread_alerts || profileDetails.count_unread_alerts
      }
      dispatch(setDetails({ details: profileDetails }))
      setLocalStore('PROFILE_DETAILS', profileDetails)
    }
  }
  customMessage.display && dispatch(showSnackbar(customMessage))
  return customResponse
}

export function onResponseError (error) {
  const { dispatch } = reduxStore
  let customError = { ...error }
  const status = Number(
    error.response ? error.response.headers.status || error.response.status : -1
  )
  if (status === 401 && window.location.pathname !== '/') {
    clearLocalStore()
    window.location.reload('/')
  } else if (status === 500) {
    customError = getUnknownError()
  } else {
    if (status === 403) {
      customError = {
        response: {
          message: 1000,
          status: status,
          display: true,
          type: 'ERROR'
        }
      }
      const lang = localStorage.getItem('lang')
      localStorage.clear()
      localStorage.setItem('lang', lang)
      console.clear()
    } else {
      customError = {
        response: {
          message:
            error.response.data.message instanceof Array
              ? error.response.data.message[0].response_code
              : error.response.data.message.response_code,
          status: status,
          display:
            error.response.data.message instanceof Array
              ? error.response.data.message[0].display
              : error.response.data.message.display,
          type: 'ERROR'
        }
      }
    }
  }
  customError.response.display && dispatch(showSnackbar(customError.response))
  customError.response.message === 1038 && setTimeout(function () {
    const lang = localStorage.getItem('lang')
    localStorage.clear()
    localStorage.setItem('lang', lang)
    window.location.href = '/'
  }, 3000)

  return Promise.reject(customError)
}

const getUnknownError = (message) => ({
  response: {
    message:
      message && typeof message === typeof ''
        ? message
        : 'Service unavailable. Please try after some time.',
    display: true,
    status: 500,
    type: 'ERROR'
  }
})
