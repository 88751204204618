export const phoneRegExp = /\d{3}-\d{3}-\d{4}/
// eslint-disable-next-line
export const emailRegExp = /^([a-zA-Z0-9_\-\+\.]+)@([a-zA-Z0-9_\-\+\.]+)\.([a-zA-Z]{2,5})$/;
// eslint-disable-next-line
export const passwordRegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~])[A-Za-z\d`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]{8,}$/;
// eslint-disable-next-line
export const nameRegExp = /^[a-zA-Z ]{2,30}$/;

export const UI_DATE_FORMAT = 'DD MMM YYYY'
export const UI_TIME_FORMAT = 'h:mm a'
export const UI_24_HOUR_FORMAT = 'HH:mm'
export const BE_DATE_FORMAT = 'YYYY-MM-DD'
export const UI_DATE_FORMAT_WITH_DAY = 'DD MMM YYYY dddd'
export const UI_DATE_RTL = 'dddd YYYY MMM DD'
export const serverUnavailableCodes = [500, 503]

export const LANG_TIME_FORMAT = ['fr', 'ar']
