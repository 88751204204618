import logoen from '../../assets/logo.png'
import logofr from '../../assets/logofr.png'
import logoar from '../../assets/IPU_Arabic_logo_White.png'
import englishLogoCol from '../../assets/hamburger_header.webp'
import frenchLogoCol from '../../assets/frenchLogoCol.png'
import arabicLogoColored from '../../assets/arabicLogoColored.png'

export const languageLogos = {
  en: logoen,
  fr: logofr,
  ar: logoar
}

export const languageLogosLight = {
  en: englishLogoCol,
  fr: frenchLogoCol,
  ar: arabicLogoColored
}
