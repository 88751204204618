import React, { useEffect, useState } from 'react'
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer'

import {
  Wrapper,
  SideBarHeader,
  HeaderImage,
  SideBarBody,
  SideBarFooter,
  RegisterButton,
  LoginButton,
  CountrySelect
} from './style'
import LanguageSwitch from '../../components/LanguageSwitch'

import { Link } from 'react-router-dom'
import { languageLogosLight } from '../../utils/lang/logos'
import { useTranslation } from 'react-i18next'
import store from '../../store'

const HamburgerMenu = ({
  isOpen,
  closeDrawer,
  openDrawer,
  setLangChange,
  t
}) => {
  const getYear = () => {
    const d = new Date()
    return d.getFullYear()
  }
  const { i18n } = useTranslation()

  React.useEffect(() => {
    const languageLogo = languageLogosLight[i18n.language]
    languageLogo ? setLogo(languageLogo) : setLogo(languageLogosLight.en)
  }, [t])
  const [logo, setLogo] = React.useState(languageLogosLight.en)

  const getAnchor = () => (store.getState().lang.textDirection === 'rtl' ? 'right' : 'left')
  const [anchor, setAnchor] = useState(getAnchor())
  useEffect(() => {
    setTimeout(() => { // Setting value after animation complete
      setAnchor(getAnchor())
    }, 1000)
  }, [store.getState().lang.textDirection])

  return (
    <Wrapper>
      <SwipeableDrawer
        anchor={anchor}
        open={isOpen}
        onClose={closeDrawer}
        onOpen={openDrawer}
      >
        <SideBarHeader>
          <HeaderImage src={logo} />
        </SideBarHeader>
        <CountrySelect>
          <div className='langSw'>
            <LanguageSwitch
              onChange={() => closeDrawer()}
              setLangChange={setLangChange}
            />
          </div>
        </CountrySelect>
        <SideBarBody>
          <LoginButton>
            <Link to='./login'>{t('login.heading')}</Link>
          </LoginButton>
          <RegisterButton>
            <Link to='./signup'>{t('register.heading')}</Link>
          </RegisterButton>
        </SideBarBody>
        <SideBarFooter>{t('Copyright')} {getYear()}</SideBarFooter>
      </SwipeableDrawer>
    </Wrapper>
  )
}

export default HamburgerMenu
