import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import Button from '../../components/UI/Button'
import { Container, Heading, Message, HeaderWrap, BottomWrapper } from './style'
import globe from '../../assets/globe.png'
import { getLocalStore } from '../../utils/utils'
import MenuImg from '../../assets/menu.png'
import Drawer from '../../components/HamburgerMenu'
import { useHistory } from 'react-router-dom'

export default function NotFound () {
  const history = useHistory()
  const { t, i18n } = useTranslation()
  const auth = getLocalStore('AUTH_DETAILS_MOBILE')
  const [isOpen, setIsOpen] = useState(false)

  const closeDrawer = () => {
    setIsOpen(false)
  }

  const openDrawer = () => {
    setIsOpen(true)
  }
  return (
    <Container i18n={i18n}>
      <HeaderWrap>
        {auth && <img src={MenuImg} id='menuIcon' onClick={() => openDrawer()} />}
      </HeaderWrap>
      <BottomWrapper>
        <div className='centerWrapper'>
          <Heading i18n={i18n}><span>4</span>
            <span className='image'>
              <img src={globe} width='99px' height='99px' />
            </span>
            <span className='endFour'>4</span>
          </Heading>
          <Message>{t('apologies')}</Message>
          {auth
            ? (<Button
                className='homeButton'
                onClick={() => { history.push('/faq') }}
                label={t('goHome')}
               />)
            : (<Button
                className='homeButton'
                onClick={() => { history.push('/login') }}
                label={t('goLogin')}
               />)}
        </div>
      </BottomWrapper>
      <Drawer
        isOpen={isOpen}
        openDrawer={openDrawer}
        closeDrawer={closeDrawer}
      />
    </Container>
  )
}
